import React from "react";
import "./Question.css";

export default class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: <p>...</p>,
      answers: <p>...</p>,
      correct: null,
    };
  }

  componentDidMount = () => {
    this.updateQuestion();
  };

  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  updateQuestion = () => {
    this.setState({
      correct: null,
    });
    fetch(
      "https://opentdb.com/api.php?amount=1&category=" +
        this.getRndInteger(9, 28),
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const results = res.results[0];
        let answers = results.incorrect_answers;
        answers.push(results.correct_answer);

        let answerElements = [];

        for (let i = 0; i < answers.length; i++) {
          answerElements.push(
            <li
              onClick={this.checkAnswer}
              key={i + Math.random()}
              className={"c-question--answer"}
              data-answer={answers[i]}
              dangerouslySetInnerHTML={{ __html: answers[i] }}
            />
          );
        }

        answerElements.sort(() => Math.random() - 0.5);
        this.setState({
          category: "",
          question: (
            <p
              style={{ fontWeight: "600" }}
              dangerouslySetInnerHTML={{ __html: results.question }}
            />
          ),
          answers: (
            <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
              {answerElements}
            </ol>
          ),
          correct: results.correct_answer,
        });
      });
  };

  checkAnswer = (e) => {
    if (e.target.dataset.answer === this.state.correct) {
      e.target.classList.add("correct");
    } else {
      e.target.classList.add("incorrect");
    }
  };

  render = () => {
    const { question, answers } = this.state;
    return (
      <div className="c-question">
        {question}
        {answers}
        <button className="button" onClick={this.updateQuestion}>
          New Question
        </button>
      </div>
    );
  };
}
